<template>
  <div class="app-wrapper">
    <div class="app-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { b64DecodeUnicode } from '@/helpers';

export default {
  name: 'App',
  created() {
    this.$store.dispatch('fetchI18N');
    this.$store.dispatch('fetchSocialNetworks');
  },
  watch: {
    '$route.query'(query) {
      if (query) {
        this.handleReferral();
      }
    },
  },
  methods: {
    handleReferral() {
      const base64Data = this.$route.query.click;

      if (base64Data) {
        localStorage.setItem('referralInfo', b64DecodeUnicode(base64Data));
        const parsedData = JSON.parse(b64DecodeUnicode(base64Data));

        this.sendGTMEvent(parsedData);
      }
    },
    sendGTMEvent(options) {
      const googleEvents = options.googleEvents;

      if (googleEvents && googleEvents.length) {
        googleEvents.forEach(eventData => {
          const key = Object.keys(eventData)[0];
          const value = eventData[key];

          this.$gtm.trackEvent({
            event: null, // Event type [default = 'interaction'] (Optional)
            category: 'Calculator',
            action: 'click',
            label: key,
            value: value,
            noninteraction: false, // Optional
          });
        });
      }
    }
  }
}
</script>

<style lang="scss">
@import "normalize-scss";
@include normalize();
@import "@/assets/styles/helpers";
@import "@/assets/styles/fonts";
@import "@/assets/styles/mixins";

.app-wrapper {
  font-family: 'Mona Sans';
  background: #ffffff;
  display: flex;
  justify-content: center;
}

.app-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;

  @include respond-above(sm) {
    box-sizing: border-box;
  }
}

.no-gutters {
  .app-content-wrapper {
    padding-left: 0;
    padding-right: 0;
    z-index: 20;
    margin: 0 auto;
  }
}

.app-content-wrapper {
  position: relative;
  width: calc(100% - 30px);
  max-width: 1280px;
  padding: 0 40px;
  display: flex;

  @include respond-between(md, lg) {
    max-width: 1024px;
    padding: 0 40px;
  }

  @include respond-between(sm, md) {
    max-width: 700px;
  }

  @include respond-to(xs) {
    padding: 0;
    box-sizing: border-box;
  }

  .with-background {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 60px 45px;

    @include respond-to(xs) {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
  }
}

.web3modal-modal-lightbox {
  z-index: 999 !important;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
}

main {
  flex: 1;
}


body,
html {
  margin: 0;
  padding: 0;
}

header,
main,
footer {
  margin: 0;
  display: block;
}

header {
  height: 40px;
  background-color: #ccc;
}

/*footer {*/
/*  height: 80px;*/
/*  background-color: orange;*/
/*}*/

</style>
