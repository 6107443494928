<template>
  <div class="sidebar">
    <ul>
      <li>
        <router-link to="/deals">
          <svg
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.0019 0.661713C13.1043 0.864258 13.1928 1.07874 13.2662 1.30471L14.1939 4.15983H17.1959C21.313 4.15983 23.0248 9.4282 19.694 11.8482L17.2653 13.6127L18.193 16.4678C18.5142 17.4563 18.467 18.4209 18.1502 19.2639L16.7461 18.7361C16.9442 18.2091 16.9817 17.5939 16.7664 16.9314L15.502 13.0398L18.8124 10.6346C20.9676 9.06877 19.8599 5.65983 17.1959 5.65983H13.1041L11.8396 1.76823C11.7896 1.61415 11.7302 1.47098 11.6631 1.33827L13.0019 0.661713ZM8.0353 2.23175C8.40949 1.08011 10.0387 1.08011 10.4129 2.23175L12.0142 7.15982H17.1958C18.4067 7.15982 18.9102 8.70934 17.9306 9.42109L13.7385 12.4668L15.3397 17.3949C15.7139 18.5465 14.3958 19.5042 13.4162 18.7924L9.22412 15.7467L5.03205 18.7924C4.05241 19.5042 2.73431 18.5465 3.1085 17.3949L4.70973 12.4668L0.517664 9.42109C-0.461978 8.70934 0.041492 7.15982 1.2524 7.15982H6.43407L8.0353 2.23175ZM9.22412 3.42704L7.80453 7.79609C7.63719 8.31112 7.15724 8.65982 6.61571 8.65982H2.02182L5.73835 11.36C6.17646 11.6783 6.35978 12.2426 6.19244 12.7576L4.77285 17.1266L8.48939 14.4264C8.9275 14.1081 9.52074 14.1081 9.95885 14.4264L13.6754 17.1266L12.2558 12.7576C12.0885 12.2426 12.2718 11.6783 12.7099 11.36L16.4264 8.65982H11.8325C11.291 8.65982 10.8111 8.31112 10.6437 7.79609L9.22412 3.42704Z"
              fill="#6A6A71"
            />
          </svg>
          Deals
        </router-link>
      </li>
      <li>
        <router-link to="/my-investments">
          <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
          >
            <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.0019 0.661713C13.1043 0.864258 13.1928 1.07874 13.2662 1.30471L14.1939 4.15983H17.1959C21.313 4.15983 23.0248 9.4282 19.694 11.8482L17.2653 13.6127L18.193 16.4678C18.5142 17.4563 18.467 18.4209 18.1502 19.2639L16.7461 18.7361C16.9442 18.2091 16.9817 17.5939 16.7664 16.9314L15.502 13.0398L18.8124 10.6346C20.9676 9.06877 19.8599 5.65983 17.1959 5.65983H13.1041L11.8396 1.76823C11.7896 1.61415 11.7302 1.47098 11.6631 1.33827L13.0019 0.661713ZM8.0353 2.23175C8.40949 1.08011 10.0387 1.08011 10.4129 2.23175L12.0142 7.15982H17.1958C18.4067 7.15982 18.9102 8.70934 17.9306 9.42109L13.7385 12.4668L15.3397 17.3949C15.7139 18.5465 14.3958 19.5042 13.4162 18.7924L9.22412 15.7467L5.03205 18.7924C4.05241 19.5042 2.73431 18.5465 3.1085 17.3949L4.70973 12.4668L0.517664 9.42109C-0.461978 8.70934 0.041492 7.15982 1.2524 7.15982H6.43407L8.0353 2.23175ZM9.22412 3.42704L7.80453 7.79609C7.63719 8.31112 7.15724 8.65982 6.61571 8.65982H2.02182L5.73835 11.36C6.17646 11.6783 6.35978 12.2426 6.19244 12.7576L4.77285 17.1266L8.48939 14.4264C8.9275 14.1081 9.52074 14.1081 9.95885 14.4264L13.6754 17.1266L12.2558 12.7576C12.0885 12.2426 12.2718 11.6783 12.7099 11.36L16.4264 8.65982H11.8325C11.291 8.65982 10.8111 8.31112 10.6437 7.79609L9.22412 3.42704Z"
                    fill="#6A6A71"
            />
          </svg>
          My Investments
        </router-link>
      </li>
      <li>
        <router-link to="/privacy">
          <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
          >
            <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.0019 0.661713C13.1043 0.864258 13.1928 1.07874 13.2662 1.30471L14.1939 4.15983H17.1959C21.313 4.15983 23.0248 9.4282 19.694 11.8482L17.2653 13.6127L18.193 16.4678C18.5142 17.4563 18.467 18.4209 18.1502 19.2639L16.7461 18.7361C16.9442 18.2091 16.9817 17.5939 16.7664 16.9314L15.502 13.0398L18.8124 10.6346C20.9676 9.06877 19.8599 5.65983 17.1959 5.65983H13.1041L11.8396 1.76823C11.7896 1.61415 11.7302 1.47098 11.6631 1.33827L13.0019 0.661713ZM8.0353 2.23175C8.40949 1.08011 10.0387 1.08011 10.4129 2.23175L12.0142 7.15982H17.1958C18.4067 7.15982 18.9102 8.70934 17.9306 9.42109L13.7385 12.4668L15.3397 17.3949C15.7139 18.5465 14.3958 19.5042 13.4162 18.7924L9.22412 15.7467L5.03205 18.7924C4.05241 19.5042 2.73431 18.5465 3.1085 17.3949L4.70973 12.4668L0.517664 9.42109C-0.461978 8.70934 0.041492 7.15982 1.2524 7.15982H6.43407L8.0353 2.23175ZM9.22412 3.42704L7.80453 7.79609C7.63719 8.31112 7.15724 8.65982 6.61571 8.65982H2.02182L5.73835 11.36C6.17646 11.6783 6.35978 12.2426 6.19244 12.7576L4.77285 17.1266L8.48939 14.4264C8.9275 14.1081 9.52074 14.1081 9.95885 14.4264L13.6754 17.1266L12.2558 12.7576C12.0885 12.2426 12.2718 11.6783 12.7099 11.36L16.4264 8.65982H11.8325C11.291 8.65982 10.8111 8.31112 10.6437 7.79609L9.22412 3.42704Z"
                    fill="#6A6A71"
            />
          </svg>
          Privacy Policy
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TemporarySidebar",
};
</script>

<style scoped lang="scss">
.sidebar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 60px 0px 24px 16px;
  gap: 40px;
  min-width: 215px;
  background: #f5f5ff;
  border-right: 1px solid #d6d6e4;
}
ul {
  list-style: none;
  padding: 0;
  width: 100%;
  li {
    a {
      text-decoration: none;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 12px 16px;
      gap: 8px;
      height: 48px;
      font-family: "Mona Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      /* identical to box height, or 133% */
      letter-spacing: -0.01em;
      font-feature-settings: "zero" on;
      width: 100%;
      /* Dashboard */
      color: #6a6a71;

      &:hover {
        background: #d6d6e4;
        color: #1a1a23;
        cursor: pointer;
        svg{
          path{
            fill: #1a1a23;
          }
        }
      }

    }
    .router-link-active{
      background: #d6d6e4;
      color: #1a1a23;
      cursor: pointer;
      svg{
        path{
          fill: #1a1a23;
        }
      }
    }
  }
}
</style>
