<template>
    <div class="load-content">
        <img :src="require(`../assets/zkRaise_icons/${image}.svg`)" alt="">
        <div class="load-content-title">{{title}}</div>
        <div class="load-content-description">{{description}}</div>

    </div>
</template>

<script>
    export default {
        name: "LoadComponent",
        props:{
            title: String,
            description: String,
            image: String,
        }
    }

</script>

<style scoped lang="scss">
.load-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    &-title{
        font-family: 'Mona Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -0.01em;
        color: #6A6A71;
    }
    &-description{
        font-family: 'Mona Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #A7A8B0;
    }
}
</style>