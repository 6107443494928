export const SET_WEB3 = 'SET_WEB3';
export const SET_USER_INVESTMENTS = 'SET_USER_INVESTMENTS';
export const SET_USER_OLD_INVESTMENTS = 'SET_USER_OLD_INVESTMENTS';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const RESET_STATE = 'RESET_STATE';
export const SET_PROJECTS = 'SET_PROJECTS';
export const RESET_PROJECTS = 'RESET_PROJECTS';
export const SET_PAGINATION_META = 'SET_PAGINATION_META';
export const SET_I18N = 'SET_I18N';
export const SET_SOCIAL_NETWORKS = 'SET_SOCIAL_NETWORKS';
export const SET_PENDING_REQUEST_STATUS = 'SET_PENDING_REQUEST_STATUS';
export const SET_LOADED_REQUEST_STATUS = 'SET_LOADED_REQUEST_STATUS';
export const RESET_REQUEST_STATUS = 'RESET_REQUEST_STATUS';
export const SET_TOKEN_CONTRACT = 'SET_TOKEN_CONTRACT';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_SWAP_ALLOWANCE_AMOUNT = 'SET_SWAP_ALLOWANCE_AMOUNT';
export const SET_ALLOWANCE_INCREASE_STATUS = 'SET_ALLOWANCE_INCREASE_STATUS';
export const UPDATE_INVESTMENT_PROJECT_CONTRACT_INFO = 'UPDATE_INVESTMENT_PROJECT_CONTRACT_INFO';
export const SET_BLOCKCHAIN_INFO_WEB3 = 'SET_BLOCKCHAIN_INFO_WEB3';
export const SET_TOKEN_CONTRACT_INFO = 'SET_TOKEN_CONTRACT_INFO';
export const SET_OLD_TOKEN_CONTRACT_INFO = 'SET_OLD_TOKEN_CONTRACT_INFO';
export const SET_BLOG_POSTS_BY_CATEGORY = 'SET_BLOG_POSTS_BY_CATEGORY';
export const SET_BLOG_CATEGORIES = 'SET_BLOG_CATEGORIES';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_BLOG_POSTS_HOME_PAGE = 'SET_BLOG_POSTS_HOME_PAGE';
export const SET_INDUSTRIES_INNOVATION_PAGE = "SET_INDUSTRIES_INNOVATION_PAGE";
export const SET_PROJECTS_INNOVATION_PAGE = "SET_PROJECTS_INNOVATION_PAGE"
export const SET_PARTNERS_DATA = 'SET_PARTNERS_DATA';
export const SET_LEADERBOARD_DATA = 'SET_LEADERBOARD_DATA';
export const SET_ATD_TOKEN_DATA ='SET_ATD_TOKEN_DATA';
export const CLEAR_CURRENT_USER_LEADERBOARD = 'CLEAR_CURRENT_USER_LEADERBOARD';
export const BURN_LOADING = 'BURN_LOADING';
export const STAKE_LOADING = "STAKE_LOADING";
export const IS_AGREE = "IS_AGREE";
export const SET_СURRENT_LIKES_FOR_PROJECT = "SET_СURRENT_LIKES_FOR_PROJECT";
export const SET_СURRENT_INTERESTS_FOR_PROJECT = "SET_СURRENT_INTERESTS_FOR_PROJECT";
export const SET_PROJECTS_OTC_PAGE = "SET_PROJECTS_OTC_PAGE"
export const SET_CURRENT_NETWORK_DROPDOWN = "SET_CURRENT_NETWORK_DROPDOWN"
export const WALLETCONNECT_PROVIDER = "WALLETCONNECT_PROVIDER";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_TOKEN_EXPIRATION = "SET_TOKEN_EXPIRATION";
export const SET_USER_ACTIVITY = "SET_USER_ACTIVITY";
export const SET_USER_STAKES = "SET_USER_STAKES";
export const UNSTAKE_LOADING = "UNSTAKE_LOADING";
export const MINT_LOADING = "MINT_LOADING"


