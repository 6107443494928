<template>
  <div>
    <HeaderComponent />
    <div class="page-content">
      <TemporarySidebar />
      <div class="completed-deals-page-content">
        <LoadComponent
          v-if="!isActiveLoad && !isActiveLoad"
          title="Loading..."
          description="We’re preparing projects for the start"
          image="bender"
        />

        <div v-else>
          <div style="position: relative">
            <carousel
              v-if="projectsForCarousel"
              @slide-change="
                (slideIndex) =>
                  changeWrapperBgImage(slideIndex, projectsForCarousel, true)
              "
              :autoplay="this.autoplay"
              :projectsForCarousel="projectsForCarousel"
            >
            </carousel>
            <LoadComponent
              v-else
              title="Getting started soon"
              description="We’re preparing projects for the start"
              image="zoidberg"
            />
          </div>
          <div v-if="projectsForCarousel">
            <h1>Actual deals</h1>
            <div class="completed-deals-projects-wrapper">
              <project-card-small
                v-for="project in projects.completed?.filter(
                  (item, index) => index < 3
                )"
                :key="project.id"
                state="completed"
                :type="projectType(project)"
                :type-name="project.type.name"
                :name="project.name"
                :date-start="project.dateStart"
                :date-end="project.dateEnd"
                :img="`${staticEndpoint}/${project.cardBannerPath}`"
                :slug="project.slug || String(project.id)"
                :activeDaysLeftParsed="project.activeDaysLeftParsed"
                :isActive="project.isActive"
                :isUpcoming="project.isUpcoming"
                :isRefunded="project.isRefunded"
                :isFull="project.isFull"
                :linkToOpen="project.linkToOpen"
                :multiplier="project.multiplier"
                timerHead="Deal ends (D:H:M:S)"
              />
            </div>
          </div>
          <div v-if="projects?.completed.length">
            <h1>Past deals</h1>
            <div class="completed-deals-projects-wrapper">
              <project-card-small
                v-for="project in projects.completed"
                :key="project.id"
                state="completed"
                :type="projectType(project)"
                :type-name="project.type.name"
                :name="project.name"
                :date-start="project.dateStart"
                :date-end="project.dateEnd"
                :img="`${staticEndpoint}/${project.cardBannerPath}`"
                :slug="project.slug || String(project.id)"
                :activeDaysLeftParsed="project.activeDaysLeftParsed"
                :isActive="project.isActive"
                :isUpcoming="project.isUpcoming"
                :isRefunded="project.isRefunded"
                :isFull="project.isFull"
                :linkToOpen="project.linkToOpen"
                :multiplier="project.multiplier"
                timerHead="Deal ended (D:H:M:S)"
              />
            </div>
            <generic-pagination
              :current-page="pagination.current_page"
              :total-pages="pagination.last_page"
              :url="{
                name: 'Completed',
                params: ['page'],
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <FooterComponent />
  </div>
</template>

<script>
// import TopSectionWrapper from '@/components/TopSectionWrapper';
import GenericPagination from "@/components/GenericPagination.vue";
import ProjectCardSmall from "@/components/ProjectCardSmall";
import Carousel from "@/components/carousel/Carousel";

import { mapActions, mapGetters, mapState } from "vuex";
import { projectType } from "@/helpers";
import HeaderComponent from "@/components/TemporaryHeader";
import FooterComponent from "@/components/TemporaryFooter";
import TemporarySidebar from "@/components/TemporarySidebar";
import LoadComponent from "@/components/LoadComponent";

export default {
  name: "CompletedDealsPage",
  components: {
    LoadComponent,
    TemporarySidebar,
    FooterComponent,
    HeaderComponent,
    // TopSectionContent,
    // TopSectionWrapper,
    GenericPagination,
    ProjectCardSmall,
    Carousel,
  },
  head: {
    meta: [
      {
        name: "description",
        content:
          "Private and seed round deals of various crypto projects that have already been completed.",
      },
    ],
  },
  data() {
    return {
      isActiveLoad: false,
      isCompletedLoad: false,
    };
  },
  computed: {
    projectImages() {
      return this.projectsForCarousel?.map((project) => {
        return {
          bannerPath: project?.bannerPath
            ? project?.bannerPath
            : project?.cardBannerPath,
          bannerMobilePath: project?.bannerMobilePath,
        };
      });
    },
    bannersImages() {
      return this.banners?.map((banner) => {
        return {
          bannerPath: banner.bannerPath
            ? banner.bannerPath
            : banner.cardBannerPath,
          bannerMobilePath: banner.bannerMobilePath,
        };
      });
    },
    projectsForCarousel() {
      if (
        this.projects?.active_not_is_full?.length > 0 ||
        this.projects?.upcoming?.length > 0
      ) {
        return [
          ...this.projects?.active_not_is_full,
          ...this.projects?.upcoming,
        ];
      }
      console.log(this.projects.completed?.filter((item, index) => index < 3));
      return this.projects.completed?.filter((item, index) => index < 3);
    },
    projectsUnderCarousel() {
      if (this.projects?.active_not_is_full?.length > 0) {
        return this.projects?.active_not_is_full;
      }

      return [];
    },
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapGetters(["projects", "i18n"]),
    ...mapState(["pagination", "userProfile"]),
  },
  methods: {
    projectType,
    ...mapActions(["fetchCompletedProjects"]),
    getBannerImage(item, isProject = false) {
      const staticEndpoint = this.staticEndpoint;
      let path = "";

      if (this.$isMobile()) {
        if (isProject) {
          path += item.bannerMobilePath
            ? `${staticEndpoint}/${item.bannerMobilePath}`
            : "";
        } else {
          return item.bannerMobilePath;
        }
      } else {
        let bannerPath = item.bannerPath
          ? item.bannerPath
          : item.cardBannerPath;
        if (isProject) {
          path += bannerPath ? `${staticEndpoint}/${bannerPath}` : "";
        } else {
          return bannerPath;
        }
      }

      return path;
    },
    changeWrapperBgImage(index, images, isProject = false, isBottom = false) {
      if (isBottom) {
        this.currentBannerBottom = this.getBannerImage(
          images[index],
          isProject
        );
      } else {
        this.currentBannerUpper = this.getBannerImage(images[index], isProject);
      }
    },
  },
  watch: {
    $route(to, from) {
      if (from.name === to.name) {
        this.fetchCompletedProjects(to.query.page);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("resetProjects");
    });
  },
  async created() {
    const pageInd = this.$route.query.page;

    await this.fetchCompletedProjects(pageInd);
    this.isActiveLoad = true;
    this.isCompletedLoad = true;

    console.log(this.projects);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
h1 {
  font-family: "Mona Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: #1a1a23;
}
.completed-deals-page-content {
  padding: 40px;
  display: flex;
  flex-direction: column;
  background: #f5f5ff;
  /*width: calc(100vw - 200px);*/
  min-height: calc(100vh - 226px);

  max-width: 1160px;
  width: 100%;
  margin: auto;
  align-items: center;
}
.completed-deals-projects-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  min-width: 100%;
  width: 100%;
  gap: 16px;
}

.heading {
  font-size: 88px;
  line-height: 80px;
  max-width: 500px;
  display: inline-block;
  position: relative;
  margin-top: -40px;

  @include respond-to(xs) {
    font-size: 48px;
    line-height: 50px;
  }

  @include respond-to(sm) {
    font-size: 48px;
    max-width: 400px;
    line-height: 50px;
    margin-top: 0;
  }
}

.sub-heading {
  max-width: 663px;
  margin-top: 11px;

  @include respond-to(sm) {
    max-width: 400px;
  }
}
.page-content {
  display: flex;
  background: #f5f5ff;
  min-height: calc(100vh - 146px);

}
</style>
