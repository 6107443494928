<template>
  <div>
    <HeaderComponent />
    <div class="page-content">
      <TemporarySidebar />
      <div class="investments">
        <!--        <LoadComponent-->
        <!--          v-if="!isLoading"-->
        <!--          title="Loading..."-->
        <!--          description="We’re preparing projects for the start"-->
        <!--          image="bender"-->
        <!--        />-->
        <div class="investments-content">
          <h1>My Investments</h1>
          <div class="investments-content-header">
            <div>Pool</div>
            <div>Status</div>
            <div>Locked</div>
            <div>Earned</div>
            <div>Claimed</div>
            <div>Next Claim</div>
            <div></div>
          </div>
          <div
            v-if="investments && investments.length"
            style="display: flex; flex-direction: column; gap: 8px"
          >
            <div
              class="investments-content-item"
              v-for="project in investments"
              :key="project.id"
            >
              <div
                style="
                  display: flex;
                  justify-content: left;
                  min-width: 300px;
                  gap: 24px;
                  align-items: center;
                "
              >
                <img
                  class="project-participated-logo"
                  :src="staticEndpoint + project.logoPath"
                  :alt="projectName"
                />
                {{ project.name }}
              </div>
              <div>
                <template v-if="!project.isActive">
                  <span class="project-status-completed">Completed</span>
                </template>
                <template v-else>
                  <span class="project-status-active">
                    Active
                  </span>
                </template>
              </div>
              <div>
                <div
                  class="stake-wrapper-locked"
                  :class="{
                    hide: !parseFloat(Number(project.contractInfo?.yourStake)),
                  }"
                >
                  <span class="block">
                    <span class="your-stake">{{
                      parseFloat(
                        Number(project.contractInfo?.yourStake).toFixed(2)
                      ).toLocaleString() ?? "0"
                    }}</span>
                    {{ project.currency ?? newTokenName }}
                  </span>
                  <span>
                    <span
                      v-if="parseFloat(Number(project.contractInfo?.refunded))"
                      class="staking-limit project-participated-additional-info"
                      style="font-size: 14px"
                    >
                      (<a
                        style="
                          color: #000000;
                          line-height: 35px;
                          font-size: 14px;
                        "
                        :href="`${project.refundLink}`"
                        target="_blank"
                        >{{
                          parseFloat(
                            Number(project.contractInfo?.refunded).toFixed(2)
                          ).toLocaleString() ?? "0"
                        }}
                        refunded</a
                      >)
                    </span>
                  </span>
                </div>
                <template
                  v-if="!parseFloat(Number(project.contractInfo?.yourStake))"
                >
                  <div class="stake-wrapper-unlocked">
                    <span
                      class="project-participated-additional-info"
                      v-html="i18n['investments-all-atd-unlocked']"
                    >
                    </span>
                  </div>
                </template>
              </div>
              <div>
                <template v-if="project.isNftWhitelistDrop">
                  <span class="nft-drop-wl-amount-ticker">1 WL</span>
                </template>
                <template v-else-if="project.isNftDrop">
                  <span>1 NFT</span>
                </template>
                <template
                  v-else-if="
                    project.isTrueYieldStaking &&
                    Number(project.contractInfo?.yourStake)
                  "
                >
                  <span
                    v-html="
                      calculateEstimation(
                        parseFloat(
                          Number(project.contractInfo.yourStake).toFixed(2)
                        ),
                        project.totalDaysFromStart,
                        parseFloat(project.launchPrice),
                        true
                      )
                    "
                  ></span>
                </template>
                <template v-else-if="!project.isRefunded">
                  <span class="estimated-allocation">
                    {{
                      !project.contractInfo?.earned ||
                      project.contractInfo?.earned === "--"
                        ? "TBA"
                        : parseFloat(
                            Number(project.contractInfo?.earned).toFixed(2)
                          ).toLocaleString() ?? "0"
                    }}
                  </span>
                  {{
                    !project.contractInfo?.earned ||
                    project.contractInfo?.earned === "--"
                      ? ""
                      : project.tickerMetric
                  }}
                </template>
                <template
                  v-if="project.isActive && !project.isTrueYieldStaking"
                >
                  <span class="block project-participated-additional-info">
                    {{
                      !project.contractInfo?.earned ||
                      project.contractInfo?.earned === "--"
                        ? ""
                        : i18n["investments-table-estimated-label"]
                    }}
                  </span>
                </template>
                <template v-else-if="project.isRefunded">
                  <span
                    class="block project-participated-additional-info refunded-label"
                  >
                    {{ i18n["investments-table-protected-label"] }}
                  </span>
                </template>
                <template v-else-if="!project.isTrueYieldStaking">
                  <span
                    class="block project-participated-additional-info earned-label"
                  >
                    {{
                      !project.contractInfo?.earned ||
                      project.contractInfo?.earned === "--"
                        ? ""
                        : i18n["investments-table-earned-label"]
                    }}
                  </span>
                </template>
              </div>
              <div>
                <template v-if="!project.isActive">
                  <template v-if="project.isNftWhitelistDrop">
                    <span class="block">
                      <template v-if="project.tokenSentPercentage">
                        <span class="nft-drop-wl-amount-claimed">1 </span>
                        <span class="nft-drop-wl-amount-claimed-ticker"
                          >WL</span
                        >
                      </template>
                      <template v-else>
                        <span class="nft-drop-wl-amount-claimed">-</span>
                      </template>
                    </span>
                  </template>
                  <template v-else-if="project.isNftDrop">
                    <span class="block">
                      <template v-if="project.tokenSentPercentage">
                        <span class="nft-drop-wl-amount-claimed">1 </span>
                        <span class="nft-drop-wl-amount-claimed-ticker"
                          >NFT</span
                        >
                      </template>
                      <template v-else>
                        <span class="nft-drop-wl-amount-claimed">-</span>
                      </template>
                    </span>
                  </template>
                  <template v-else-if="project.isRefunded">
                    {{ i18n["investments-table-protected-label"] }}
                  </template>
                  <template v-else>
                    <span class="block">
                      <span class="your-claimed">{{
                        calculateClaimed(project)
                      }}</span>
                      <span
                        class="your-claimed-ticker"
                        :class="{
                          hide: !(
                            Number(project.contractInfo?.earned).toFixed(2) >
                              0 && project.tokenSentPercentage > 0
                          ),
                        }"
                      >
                        {{ project.tickerMetric }}
                      </span>
                    </span>
                  </template>
                  <template
                    v-if="project.tokenSentPercentage && !project.isRefunded"
                  >
                    ({{ project.tokenSentPercentage }}%)
                  </template>
                </template>
                <template v-else> - </template>
              </div>
              <div>
                <template
                  v-if="project.tokenSentPercentage && project.dateNextClaim"
                >
                  <span class="block"
                    >{{ project.nextClaimPercentage }}% at</span
                  >
                  <span class="block">{{
                    project.dateNextClaimFormatted
                  }}</span>
                </template>
                <template v-else> - </template>
              </div>
              <div>
                <template v-if="project.showClaimButton">
                  <template v-if="project.externalClaimLink">
                    <button-component
                      class="project-participated-button investment-manage-button external-claim-link"
                      font-size="12px"
                      font-weight="600"
                      @click="navigateToClaimLink($event, project)"
                    >
                      {{ i18n["claim-button-label"] }}
                    </button-component>
                  </template>
                  <template
                    v-else-if="
                      project.claimProofs &&
                      project.ableToClaim &&
                      project.isUserAbleToCLaim &&
                      project.addressCanClaim
                    "
                  >
                    <button-component
                      class="project-participated-button investment-claim-button"
                      @click="handleClaim($event, project)"
                      font-size="12px"
                      font-weight="600"
                    >
                      {{ i18n["claim-button-label"] }}
                    </button-component>
                  </template>
                </template>
                <template
                  v-else-if="
                    unstakeIsEnabled &&
                    !project.isActive &&
                    !project.isIDO &&
                    Number(project.contractInfo?.yourStake)
                  "
                >
                  <button-component
                    class="project-participated-button investment-unstake-button"
                    @click="handleUnstake($event, project)"
                    :disabled="isUnstakeBtnDisabled"
                    font-size="12px"
                    font-weight="600"
                  >
                    {{ unstakeBtnTxt }}
                  </button-component>
                </template>
                <template v-if="project.chatLink">
                  <a
                    style="color: #000000; line-height: 35px"
                    :href="`${project.chatLink}`"
                    target="_blank"
                    >Join chat</a
                  >
                </template>
                <template v-else> </template>
              </div>
            </div>
          </div>
          <LoadComponent
            v-else
            title="You have not invested yet"
            description="Your investments will appear here"
            image="zoidberg"
          />
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TemporarySidebar from "@/components/TemporarySidebar";
import FooterComponent from "@/components/TemporaryFooter";
import HeaderComponent from "@/components/TemporaryHeader";
import LoadComponent from "@/components/LoadComponent";
import { calculateEstimation } from "@/helpers";
import { useToast } from "vue-toastification";
import ButtonComponent from "@/components/Button";

export default {
  name: "InvestmentsPage",
  components: {
    TemporarySidebar,
    FooterComponent,
    HeaderComponent,
    LoadComponent,
    ButtonComponent,
  },
  data() {
    return {
      isLoading: false,
      unstakeBtnTxt: "Unstake",

    };
  },
  setup() {
    const toast = useToast();
    return { toast, calculateEstimation };
  },
  computed: {
    ...mapState([
      "web3",
      "requestStatus",
      "i18n",
      "investments",
      "oldInvestments",
      "userProfile",
      "newTokenName",
    ]),
    ...mapGetters(["userInvestments", "userOldInvestments"]),
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
  },
  methods: {
    ...mapActions(["fetchUserInvestments"]),
    calculateClaimed(project) {
      if (
        Number(project.contractInfo?.earned) > 0 &&
        project.tokenSentPercentage > 0
      ) {
        return `${parseFloat(
          (
            (Number(project.contractInfo.earned) *
              project.tokenSentPercentage) /
            100
          ).toFixed(2)
        )} `;
      }

      return "- ";
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
      };
      return date
        .toLocaleDateString("en-US", options)
        .replace(",", "")
        .replace(/\//g, "-")
        .replace(" ", " ")
        .replace(/-/g, "/");
    },

    getType(action) {
      switch (action) {
        case "add_boost_points":
          return "Stake";
        case "add_to_refunded":
          return "Refund";
        case "burn":
          return "Burn";
        case "invest":
          return "Invest";
        case "referral":
          return "Stake";
        case "refund_full":
          return "Full refund";
        case "remove_from_refunded":
          return "Refund";
        case "stake":
          return "Stake";
        case "stake_bonus":
          return "Stake";
      }
    },
    currency(action) {
      return this.getType(action) === "Invest" ||
        this.getType(action) === "Refund" ||
        this.getType(action) === "Full refund"
        ? "USDT"
        : "ATD";
    },
  },
  async mounted() {
    await this.fetchUserInvestments();
    this.isLoading = true;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
h1 {
  font-family: "Mona Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.02em;
  color: #1a1a23;
}
.page-content {
  display: flex;
  background: #f5f5ff;
}
.investments {
  padding: 40px;
  display: flex;
  flex-direction: column;
  background: #f5f5ff;
  min-height: calc(100vh - 226px);

  /*width: calc(100vw - 200px);*/
  max-width: 1392px;
  width: 100%;
  margin: auto;
  overflow: auto;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 24px;
      gap: 24px;
      height: 64px;
      background: #e6e6f5;
      border-radius: 16px;
      justify-content: space-between;
      width: fit-content;
      div {
        min-width: 150px;
        display: flex;
        flex-wrap: wrap;
      }
      :first-child {
        min-width: 300px;
      }
    }
    &-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 24px;
      gap: 24px;
      height: fit-content;
      min-height: 64px;
      background: #ffffff;
      border: 1px solid #d6d6e4;
      border-radius: 16px;
      justify-content: space-between;
      width: fit-content;
      text-decoration: none;
      div {
        min-width: 150px;
      }
      :last-child {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      img {
        height: 45px;
        border-radius: 50%;
      }
    }
  }
}
.project-participated-logo-blockchain {
  width: 15px;
}
.project-participated-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;
  height: 32px;
  background: #374BFF;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;

  &.investment-manage-button {
    //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
  }

  &.investment-unstake-button {
    //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
  }

  &.investment-claim-button {
    //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
  }
}
.hide {
  display: none !important;
}
  .project-status{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    &-active{
      font-family: 'Mona Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 133%;
      letter-spacing: 0.014em;
      color: #3EA33B;
      background: #DDFADC;
      width: fit-content;
      padding: 3px 8px;
      border-radius: 6px;
    }
    &-completed{
      font-family: 'Mona Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 133%;
      letter-spacing: 0.014em;
      color: #313139;
      background: #E6E6F5;
      width: fit-content;
      padding: 3px 8px;
      border-radius: 6px;
    }
  }
</style>
