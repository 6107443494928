<template>
  <div
    class="carousel"
    ref="carousel"
    v-touch:swipe.left="increaseIndex"
    v-touch:swipe.right="decreaseIndex"
  >
    <slide v-for="project in projectsForCarousel" :key="project.id">
      <div class="slide-content">
        <div class="slide-content-left-side">
          <div class="block-wrapper">
            <div class="slide-content-left-side-name">{{ project.name }}</div>
            <div class="slide-content-left-side-description">
              Here you can find deals in private and seed rounds that have
              already been completed. Here you can find deals in private and
              seed rounds that have already been completed.
            </div>
          </div>
          <div class="block-wrapper">
            <SmallTimer
              timerHead="Deal ends (D:H:M:S)"
              :activeDaysLeftParsed="project.activeDaysLeftParsed"
              :typeName="project.type.name"
            />
            <button class="slide-content-left-side-btn">Participate now</button>
          </div>
        </div>
        <img :src="`${staticEndpoint}/${project.cardBannerPath}`" />
      </div>
    </slide>
  </div>
  <div
    class="carousel-nav"
    ref="carouselNav"
    v-if="slides && slides.length > 1"
  >
    <div
      class="dot"
      v-for="(_, index) in slides"
      :key="index"
      :class="index === 0 ? 'dot-active' : ''"
      @click="
        switchSlide(index);
        disableAutoPlay();
      "
    ></div>
  </div>
</template>

<script>
import Slide from "@/components/carousel/Slide";
import { mapState } from "vuex";
import SmallTimer from "@/components/SmallTimer";

export default {
  name: "CarouselComponent",
  components: {
    Slide,
    //LearnMore,
    SmallTimer,
  },
  emits: ["slideChange"],
  props: {
    autoplay: {
      type: Boolean,
      default: false,
    },
    projectsForCarousel: Object,
  },
  data() {
    return {
      slides: null,
      nav: null,
      currentIndex: 0,
      autoplayInterval: null,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
  },
  mounted() {
    this.slides = Object.values(this.$refs.carousel.children).filter((el) => {
      return !el.classList.contains("carousel-nav");
    });

    if (this.slides.length > 0) {
      this.slides[0].classList.add("slide-active");
    }

    this.toggleAutoPlay();
  },
  beforeUnmount() {
    this.disableAutoPlay();
  },
  watch: {
    autoplay() {
      this.toggleAutoPlay();
    },
  },
  methods: {
    disableAutoPlay() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    },
    toggleAutoPlay() {
      if (this.autoplay && this.slides.length > 1) {
        this.autoplayInterval = setInterval(() => {
          this.increaseIndex();
        }, 6000);
      } else {
        this.disableAutoPlay();
      }
    },
    increaseIndex() {
      this.currentIndex++;
      this.switchSlide(this.currentIndex);
    },
    decreaseIndex() {
      this.currentIndex--;
      this.switchSlide(this.currentIndex);
    },
    switchSlide(index) {
      const amountOfSlides = this.slides.length;

      if (this.currentIndex > amountOfSlides - 1) {
        this.currentIndex = 0;
        index = 0;
      }
      if (this.currentIndex < 0) {
        this.currentIndex = amountOfSlides - 1;
        index = this.currentIndex;
      }

      Object.values(this.slides).map((slide, key) => {
        if (index !== key) {
          slide.classList.remove("slide-active");
        } else {
          slide.classList.add("slide-active");
        }
      });
      Object.values(this.$refs.carouselNav.children).map((navItem) => {
        navItem.classList.remove("dot-active");
      });
      this.$refs.carouselNav.children[index].classList.add("dot-active");

      this.$emit("slideChange", index);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.carousel {
  position: relative;
  height: 100%;
  z-index: 11;

  &-nav {
    display: flex;
    justify-content: center;
    color: #fff;
    z-index: 30;
    margin-top: 12px;
    .dot {
      width: 92.5px;
      height: 4px;
      background: #d6d6e4;
      border-radius: 4px;
      flex: none;
      &:not(:first-child) {
        margin-left: 10px;
      }

      @include respond-below(sm) {
        width: 45px;
        height: 4px;
      }

      &-active {
        background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
      }
    }
  }
}
.slide-content {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  box-sizing: border-box;
  border: 1px solid #d6d6e4;
  border-radius: 16px;
  img {
    width: 556px;
    height: 396px;
    border-radius: 10px;
    background-size: cover;
    /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
    position: relative;
    object-fit: cover;
  }
  &-left-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;
    width: 100%;
    padding: 16px;
    &-type {
      font-family: "Mona Sans";
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 8px;
      gap: 8px;
      background: #ddfadc;
      border-radius: 6px;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      letter-spacing: 0.014em;
      width: fit-content;
      color: #3ea33b;
    }
    &-name {
      font-family: "Mona Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 56px;
      letter-spacing: -0.02em;
      color: #1a1a23;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-description {
      width: 450px;
      font-family: "Mona Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: #1a1a23;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &-block {
      display: flex;
      gap: 16px;
      margin-top: 40px;
      &-soft {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px 16px;
        gap: 8px;
        width: 100%;
        height: 72px;
        border: 1px solid #d6d6e4;
        border-radius: 8px;
        &-top {
          font-family: "Mona Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.014em;
          color: #3d3d45;
        }
        &-bottom {
          font-family: "Mona Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.01em;
          hanging-punctuation: first last;
          color: #3d3d45;
        }
      }
    }
    &-btn {
      /*margin-top: 40px;*/
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 12px;
      gap: 8px;
      width: 217px;
      height: 40px;
      background: #374bff;
      border-radius: 8px;
      font-family: "Mona Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border: none;
    }
  }
  .block-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
