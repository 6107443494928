<template>
  <div
    class="project-card-bg-wrapper"
    :class="type"
    @click="goToProjectPage(slug)"
  >
    <div class="project-card-content">
      <div
        class="project-card-img"
        :style="{ backgroundImage: img ? `url(${img})` : '' }"
      ></div>
      <div class="project-card-meta">
        <div class="project-card-meta-block">
          <div class="project-card-meta-block-name">{{ name }}</div>
        </div>
        <SmallTimer
          :timerHead="timerHead"
          :activeDaysLeftParsed="activeDaysLeftParsed"
          :typeName="typeName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import SmallTimer from "@/components/SmallTimer";

export default {
  name: "ProjectCardSmall",
  components: { SmallTimer },
  props: {
    state: String,
    type: String,
    typeName: String,
    name: String,
    dateStart: String,
    dateEnd: String,
    img: String,
    activeDaysLeftParsed: {
      type: Object,
      required: true,
      validator: (value) => {
        return ["days", "hours", "minutes", "seconds"].every(
          (key) => key in value
        );
      },
    },
    isActive: Boolean,
    isUpcoming: Boolean,
    isRefunded: Boolean,
    isFull: Boolean,
    linkToOpen: String,
    multiplier: String,
    slug: {
      type: String,
      required: true,
    },
    timerHead: String,
  },
  data() {
    return {
      ended: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      currentTime: this.activeDaysLeftParsed,
    };
  },
  methods: {
    goToProjectPage(slug) {
      if (this.linkToOpen) {
        window.location.href = this.linkToOpen;
      } else {
        router.push({ name: "Project", params: { slug } });
      }
    },
    getActiveDaysLeft() {
      return this.activeDaysLeftParsed
        ? this.activeDaysLeftParsed
        : {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
    },
    setState(state) {
      if (this.isActive && this.isFull) {
        return "Full";
      }

      if (this.isRefunded) {
        return "Underinvested";
      }

      return state;
    },
    setClassState(state) {
      if (this.isActive && this.isFull) {
        return "full";
      }

      if (this.isRefunded) {
        return "refunded";
      }

      return state;
    },

    startTimer() {
      let totalSeconds = this.convertToSeconds(this.currentTime);

      this.intervalId = setInterval(() => {
        if (this.isCountdown) {
          totalSeconds--;
          if (totalSeconds < 0) {
            clearInterval(this.intervalId);
            this.currentTime = this.convertToTimeObj(0);
            return;
          }
        } else {
          totalSeconds++;
        }
        this.currentTime = this.convertToTimeObj(totalSeconds);
      }, 1000);
    },
    convertToSeconds(timeObj) {
      return (
        parseInt(timeObj.days) * 86400 +
        parseInt(timeObj.hours) * 3600 +
        parseInt(timeObj.minutes) * 60 +
        parseInt(timeObj.seconds)
      );
    },
    convertToTimeObj(totalSeconds) {
      const days = Math.floor(totalSeconds / 86400);
      totalSeconds %= 86400;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.project {
  &-name {
    line-height: 130%;
    text-align: left;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -0.035em;

    /* #272727 */

    color: #272727;

    @include respond-to(xs) {
      font-size: 16px;
    }

    @include respond-to(sm) {
      font-size: 18px;
    }

    @include respond-between(xs, sm) {
      font-size: 14px;
    }
  }

  &-dates {
    font-weight: 600;
    font-size: 13px;
    line-height: 130%;
    color: #fff;
    padding-top: 5px;
    text-align: left;

    @include respond-to(xs) {
      font-size: 11px;
    }

    @include respond-to(sm) {
      font-size: 11px;
    }
  }

  &-type {
    position: absolute;
    display: flex;
    gap: 10px;
    bottom: 5px;
    left: 15px;

    div {
      display: flex;
      min-height: 22px;
      padding: 0px 12px;
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      /* identical to box height, or 16px */
      text-align: center;
      align-items: center;

      align-items: center;
      text-align: center;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      color: #000000;

      background: #b6dc2b;
      border: 0.5px solid #000000;
    }
  }

  &-card {
    &-bg-wrapper {
      /* Tiles / Task */

      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 16px;
      gap: 8px;
      width: 376px;
      /*height: 357px;*/
      background: #ffffff;
      border: 2px solid #d6d6e4;
      border-radius: 16px;
      flex: none;
      order: 0;
      flex-grow: 0;

      text-align: center;
      cursor: pointer;
      z-index: 2;
      height: auto;

      &:nth-child(2n-1) {
        @include respond-below(sm) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {
        @include respond-below(sm) {
          margin-right: 0;
        }
      }

      &.nft {
        //background: linear-gradient(90.3deg, #FF5E7E -4.55%, #FFAD72 107.76%);

        .project-name {
          //background: linear-gradient(94.29deg, #FF3F71 -2.43%, #FFAD72 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #FF3F71 -7.52%, #FFAD72 107.59%);
        }
      }

      &.true-yield-staking {
        //background: linear-gradient(90.44deg, #AC3BFF -6.21%, #7CCAF7 106.91%);

        .project-name {
          //background: linear-gradient(94.29deg, #AC3BFF -2.43%, #5ECFFF 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #AC3BFF -7.52%, #5ECFFF 107.59%);
        }
      }

      &.ido {
        //background: linear-gradient(90.31deg, #FF7F23 -7.18%, #FFF400 104.61%);

        .project-name {
          //background: linear-gradient(94.29deg, #FF7F23 -2.43%, #FEFF5F 20.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #FF7F23 -7.52%, #FFF400 107.59%);
        }
      }

      &.lockdrop {
        //background: linear-gradient(90.31deg, #00B1D2 -7.18%, #1EB676 104.61%);

        .project-name {
          //background: linear-gradient(94.29deg, #00B1D2 -2.43%, #1EB676 47.26%);
          //-webkit-text-fill-color: transparent;
          //-webkit-background-clip: text;
        }

        button {
          //background: linear-gradient(93.1deg, #00B1D2 -7.52%, #1EB676 107.59%);
        }
      }
    }

    &-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    &-img {
      height: 181px;
      border-radius: 10px;
      background: radial-gradient(
          50% 50% at 50% 50%,
          #3c3d46 1.56%,
          #1a1b1d 100%
        )
        no-repeat center;
      background-size: cover;
      /*box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);*/
      position: relative;
    }

    &-meta {
      display: flex;
      flex-direction: column;
      gap: 32px;
      &-block {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;

        &-name {
          font-family: "Mona Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
          letter-spacing: -0.01em;
          color: #3d3d45;
        }
      }
    }
  }
}
</style>
