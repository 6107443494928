<template>
  <div class="container">
    <header-component />

    <div
      class="top-section-wrapper"
      :class="{ 'no-bg': noBg, 'about-us': aboutUs }"
    >
      <div class="app-content-wrapper">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header";

export default {
  name: "TopSectionWrapper",
  components: {
    HeaderComponent,
  },
  props: {
    bgImage: {
      type: String,
      default: "",
    },
    noBg: {
      type: Boolean,
      default: false,
    },
    aboutUs: {
      type: Boolean,
      default: false,
    },
    noDotsBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showShadeLayer: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.container {
  display: flex;
  flex-direction: column;
  gap: 23px;
}
.top-section {
  &-wrapper {
    background-size: cover;
    display: flex;
    position: relative;
    justify-content: center;
    padding-top: 85px;
    padding-bottom: 85px;
    width: 100%;
    align-self: center;
    transition: 0.5s ease all;

    &.about-us {
      background: radial-gradient(
        156.37% 129.12% at 50% 129.12%,
        #ffb800 18.7%,
        #860071 37%,
        #000b68 58.96%,
        #000000 100%
      );
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 24px;
      max-height: 838px;
      padding: 84px 0 168px;
      width: calc(100% - 40px);
      flex: 1;
      @include respond-below(lg) {
        max-height: 700px;
      }
      @include respond-below(md) {
        padding: 84px 0 68px;

      }
      @media (max-width: 380px) {
        padding: 24px 0 68px;

        max-height: 500px;

      };
    }
  }

}
</style>
