<template>
  <!-- Modal -->
  <div class="modal-mask" @click.self="closeModal">
    <div class="modal-mask-container">
      <div class="modal-mask-container-wallet" @click="connectWalletMetamask">
        <img src="../assets/wallets/metamask-logo.png" alt="" />
        <div class="modal-mask-container-wallet-name">MetaMask</div>
        <div class="modal-mask-container-wallet-description">
          Connect to your MetaMask wallet
        </div>
      </div>
      <div
        style="
          width: 100%;
          border: 1px solid rgba(195, 195, 195, 0.14);
          margin: 8px 0;
        "
      ></div>
      <div
        class="modal-mask-container-wallet"
        @click="connectWalletWalletConnect"
      >
        <img src="../assets/wallets/walletconnect.svg" alt="" />
        <div class="modal-mask-container-wallet-name">WalletConnect</div>
        <div class="modal-mask-container-wallet-description">
          Connect to your WalletConnect wallet
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BurnedModal",
  data() {
    return {};
  },
  computed: {
    ...mapState(["atdContractData", "burnLoading", "walletConnectProvider"]),
    ...mapGetters(["web3"]),
  },
  methods: {
    ...mapActions(["connectWallet", "connectWalletConnect"]),
    async connectWalletMetamask() {
      this.closeModal();
      this.$emit("handleConnect");

      // await this.connectWallet();
    },
    async connectWalletWalletConnect() {
      // this.closeModal();
      // await this.connectWalletConnect();
      this.$emit("connectWalletWalletConnect")
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
  watch: {
    walletConnectProvider() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: grid;
  place-items: center;
  z-index: 100;

  &-container {
    border: 1px solid rgba(195, 195, 195, 0.14);
    position: relative;
    width: 100%;
    background-color: rgb(39, 49, 56);
    border-radius: 12px;
    margin: 10px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    max-width: 500px;
    min-width: fit-content;
    max-height: 100%;
    text-align: center;
    padding: 8px;
    &-wallet {
      width: 100%;
      padding: 24px 0;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      border-radius: 0px;
      &:hover {
        background-color: rgb(1, 17, 25);
        border-radius: 10px;
      }
      &-name {
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        margin-top: 0.5em;
        color: rgb(199, 199, 199);
      }
      &-description {
        width: 100%;
        font-size: 18px;
        margin: 0.333em 0px;
        color: rgb(136, 136, 136);
      }
    }
  }
}
</style>
