<template>
  <main-layout>
    <template v-slot:top-section>
      <top-section-wrapper
        no-bg
        :no-dots-background="projectsForCarousel.length > 0"
      >
        <carousel
          v-if="bannersForCaraousel && bannersForCaraousel.length"
          @slide-change="
            (slideIndex) =>
              changeWrapperBgImage(slideIndex, bannersForCaraousel)
          "
          :autoplay="this.autoplay"
        >
          <slide v-for="banner in bannersForCaraousel" :key="banner.id">
            <top-section-content>
              <template v-slot:top-section-pre-heading>
                {{ banner.pre_heading }}
              </template>
              <template v-slot:top-section-heading>
                <span :class="banner.heading_classes">
                  {{ banner.heading }}
                </span>
              </template>
              <template v-slot:top-section-footer>
                <template v-for="action in banner.actions" :key="action.id">
                  <a
                    v-if="action.isBtn"
                    :href="action.link"
                    :target="action.target"
                  >
                    <button-component :class="action.classes">
                      {{ action.txt }}
                    </button-component>
                  </a>
                  <router-link
                    v-else-if="action.isBlogLink"
                    :to="{ name: 'BlogPost', params: { slug: action.link } }"
                    :class="action.classes"
                  >
                    {{ action.txt }}
                  </router-link>
                </template>
              </template>
            </top-section-content>
          </slide>
        </carousel>
        <carousel
          v-else-if="projectsForCarousel.length > 0"
          @slide-change="
            (slideIndex) =>
              changeWrapperBgImage(slideIndex, projectsForCarousel, true)
          "
          :autoplay="this.autoplay"
        >
          <slide v-for="project in projectsForCarousel" :key="project.id">
            <top-section-content
              :social-networks="project.socialNetworks"
              @left-side-mouse-over="this.autoplay = false"
              @left-side-mouse-out="this.autoplay = true"
              show-right-side
              top-section-right-content
            >
              <template v-slot:top-section-heading>
                <FontTitle :text="project.name"></FontTitle>
              </template>
              <template v-slot:top-section-sub-heading>
                {{ project.dateStart }} - {{ project.dateEnd }}
              </template>
              <template v-slot:top-section-image>
                <mq-responsive target="sm-">
                  <img :src="currentBannerUpper" />
                </mq-responsive>
              </template>
              <template v-slot:top-section-footer>
                <project-timer
                  v-if="project.isActive || project.isUpcoming"
                  :projectType="project.type.name"
                  :state="project.state"
                  :timestamp="project.timer"
                />
                <project-timer
                  v-else
                  :ended="getActiveDaysLeft(project)"
                  :projectType="getProjectType(project)"
                />
                <router-link
                  v-if="userProfile.userERCAddress && project.slug"
                  :to="{ name: 'Project', params: { slug: project.slug } }"
                  class="details-button"
                >
                  <button-component class="secondary">
                    <FontTitle text="Participate" ></FontTitle>
                    <div class="button-shadow back"></div>
                  </button-component>

                </router-link>
                <div v-else class="details-button">
                  <button-component :onClick="connectWallet">
                    <FontTitle text="Connect" ></FontTitle>
                    <div class="button-shadow back"></div>

                  </button-component>
                </div>

              </template>
              <template v-slot:top-section-right-side>
                <mq-responsive target="md+">
                  <router-link
                          v-if="userProfile.userERCAddress && project.slug"
                          :to="{ name: 'Project', params: { slug: project.slug } }"
                  >
                  <img class="main-img" :src="currentBannerUpper" />
                  </router-link>
                </mq-responsive>
              </template>
            </top-section-content>
          </slide>
        </carousel>
        <top-section-content v-else show-right-side>
          <template v-slot:top-section-heading>
            <FontTitle text="A2DAO" class="heading"></FontTitle>
          </template>
          <template v-slot:top-section-sub-heading>
            Easy investments for everyone
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
    <div class="home-page-content">
      <div class="completed-projects-wrapper">
        <!--        <div class="completed-projects-upper-image"></div>-->
        <div
          class="title-wrapper"
          :class="{
            centered:
              projects.active_not_is_full &&
              !projects.active_not_is_full.length,
          }"
        >
          Activities
        </div>
        <template
          v-if="
            projects.active_not_is_full && projects.active_not_is_full.length
          "
        >
          <div class="completed-projects">
            <project-card-small
              v-for="project in projects.active_not_is_full"
              :key="project.id"
              state="active"
              :type="projectType(project)"
              :type-name="project.type.name"
              :name="project.name"
              :date-start="project.dateStart"
              :date-end="project.dateEnd"
              :img="
                project.cardBannerPath
                  ? `${staticEndpoint}/${project.cardBannerPath}`
                  : ''
              "
              :slug="project.slug || String(project.id)"
              :activeDaysLeftParsed="project.activeDaysLeftParsed"
              :isActive="project.isActive"
              :isUpcoming="project.isUpcoming"
              :isRefunded="project.isRefunded"
              :isFull="project.isFull"
              :linkToOpen="project.linkToOpen"
              :multiplier="project.multiplier"
            />
          </div>
        </template>
        <template v-else-if="projects.upcoming && projects.upcoming.length">
          <div class="completed-projects">
            <project-card-small
              v-for="project in projects.upcoming"
              :key="project.id"
              state="upcoming"
              :type="projectType(project)"
              :type-name="project.type.name"
              :name="project.name"
              :date-start="project.dateStart"
              :date-end="project.dateEnd"
              :img="
                project.cardBannerPath
                  ? `${staticEndpoint}/${project.cardBannerPath}`
                  : ''
              "
              :slug="project.slug || String(project.id)"
              :activeDaysLeftParsed="project.activeDaysLeftParsed"
              :isActive="project.isActive"
              :isUpcoming="project.isUpcoming"
              :isRefunded="project.isRefunded"
              :isFull="project.isFull"
              :linkToOpen="project.linkToOpen"
              :multiplier="project.multiplier"
            />
          </div>
        </template>
        <template v-else>
          <span class="no-projects">There are no activities at the moment</span>
        </template>
        <!--        <div class="completed-projects-bottom-image"></div>-->
      </div>
      <div class="and-much-more-wrapper">
        <router-link :to="{ name: 'Completed' }">
          <FontTitle text="View Completed" class="and-much-more-wrapper-text"></FontTitle>
<!--          <span class="and-much-more-wrapper-text"-->
<!--            >View Completed<span class="and-much-more-wrapper-text-shadow"-->
<!--              >View Completed</span-->
<!--            ></span-->
<!--          >-->
          <div class="button-shadow-completed"></div>
        </router-link>
      </div>
      <!--<div v-if="banners && currentBannerBottom" class="banners" :style="`background-image: url(${currentBannerBottom})`">
        <div class="carousel-wrapper">
          <carousel
            @slide-change="slideIndex => changeWrapperBgImage(slideIndex, banners, true, true)"
            :autoplay="this.autoplay"
          >
            <slide v-for="banner in banners" :key="banner.id">
              <div></div>
            </slide>
          </carousel>
        </div>
      </div>
      <learn-more v-if="blogPostsHomePage && blogPostsHomePage.length" :blogs="blogPostsHomePage" />
      <div class="about-us-block-wrapper">
        <div class="title-wrapper">
          <title-component>
            About Us
          </title-component>
        </div>
        <div class="about-us-block">
          <p>
            A2DAO is a multichain fundraising platform for Web3 projects that are building the future.
          </p>

          <p>
            For more than 5 years, our team has carefully selected high-quality projects that have value for the crypto
            space. High risk ideas have never been our focus. The most important thing for us is our reputation and the
            trust of our users.
          </p>

          <p>
            Unlimited opportunities are available for $ATD token holders: access to private and seed rounds of promising
            startups, opportunity to be a part of the community of experienced investors and a lot more.
          </p>

          <strong>
            Build, invest, and earn more with A2DAO.
          </strong>
        </div>
      </div>-->
    </div>
  </main-layout>
</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import Slide from "@/components/carousel/Slide";
import ProjectCardSmall from "@/components/ProjectCardSmall";
//import TitleComponent from '@/components/Title';
import { mapActions, mapGetters, mapState } from "vuex";
import TopSectionContent from "@/components/TopSectionContent";
import { projectType } from "@/helpers";
import Carousel from "@/components/carousel/Carousel";
import ProjectTimer from "@/components/ProjectTimer";
import ButtonComponent from "@/components/Button";
//import LearnMore from '@/components/LearnMore';
import { MqResponsive } from "vue3-mq";
import FontTitle from "@/components/FontTitle";

export default {
  name: "HomePage",
  components: {
    FontTitle,
    ButtonComponent,
    TopSectionContent,
    //TitleComponent,
    TopSectionWrapper,
    MainLayout,
    Slide,
    ProjectCardSmall,
    Carousel,
    ProjectTimer,
    //LearnMore,
    MqResponsive,
  },
  head: {
    meta: [
      {
        name: 'description',
        content: 'Launchpad and ambassador platform for crypto projects. Invest in tokens and NFT.'
      },
    ],
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    projectImages() {
      return this.projectsForCarousel?.map((project) => {
        return {
          bannerPath: project?.bannerPath ? project?.bannerPath : project?.cardBannerPath,
          bannerMobilePath: project?.bannerMobilePath,
        };
      });
    },
    bannersImages() {
      return this.banners?.map((banner) => {
        return {
          bannerPath: banner.bannerPath ? banner.bannerPath : banner.cardBannerPath,
          bannerMobilePath: banner.bannerMobilePath,
        };
      });
    },
    projectsForCarousel() {
      if (
        this.projects?.active_not_is_full?.length > 0 ||
        this.projects?.upcoming?.length > 0
      ) {
        return [
          ...this.projects?.active_not_is_full,
          ...this.projects?.upcoming,
        ];
      }

      return [];
    },
    projectsUnderCarousel() {
      if (this.projects?.active_not_is_full?.length > 0) {
        return this.projects?.active_not_is_full;
      }

      return [];
    },
    isWC2022() {
      return this.$store.state.enableWC2022Features;
    },
    isXmax() {
      return this.$store.state.enableXmaxEvent;
    },
    ...mapGetters(["projects"]),
    ...mapState(["banners", "blogPostsHomePage", "userProfile"]),
  },
  methods: {
    ...mapActions(["connectWallet"]),
    getBannerImage(item, isProject = false) {
      const staticEndpoint = this.staticEndpoint;
      let path = "";

      if (this.$isMobile()) {
        if (isProject) {
          path += item.bannerMobilePath
            ? `${staticEndpoint}/${item.bannerMobilePath}`
            : "";
        } else {
          return item.bannerMobilePath;
        }
      } else {
        let bannerPath = item.bannerPath ? item.bannerPath : item.cardBannerPath
        if (isProject) {
          path += bannerPath ? `${staticEndpoint}/${bannerPath}` : "";
        } else {
          return bannerPath;
        }
      }

      return path;
    },
    changeWrapperBgImage(index, images, isProject = false, isBottom = false) {
      if (isBottom) {
        this.currentBannerBottom = this.getBannerImage(
          images[index],
          isProject
        );
      } else {
        this.currentBannerUpper = this.getBannerImage(images[index], isProject);
      }
    },
    getActiveDaysLeft(project) {
      return project?.activeDaysLeft
        ? this.project.activeDaysLeft
        : {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
    },
    getProjectType(project) {
      return project?.type?.name ? this.project.type.name : "Lockdrop";
    },
    projectType,
  },
  data() {
    return {
      //currentBanner: this.$isMobile() ? require('@/assets/wc2022_mobile.jpeg') : require('@/assets/wc2022.jpeg'),
      currentBannerUpper: "",
      currentBannerBottom: "",
      autoplay: true,
      bannersForCaraousel: [
        /*{
          id: 1298238234623,
          pre_heading: '',
          heading: 'REKTS CANCELLED',
          heading_classes: 'brawl',
          bannerPath: require('@/assets/rect_banner.jpeg'),
          bannerMobilePath: require('@/assets/rect_banner_mobile.jpeg'),
          actions: [
            {
              id: 123237565631,
              txt: 'Read More',
              link: '/blog/ido-with-community-care-program',
              classes: 'faq secondary',
              isBtn: true
            }
          ]
        }*/
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("resetProjects");
    });
  },
  created() {
    // this.$store.dispatch("fetchProjectsForHomePage").then(() => {
    //   if (this.isXmax) {
    //     this.changeWrapperBgImage(0, this.bannersForCaraousel);
    //   } else if (!this.isWC2022) {
    //     this.changeWrapperBgImage(0, this.projectImages, true);
    //   }
    // });
    // this.$store.dispatch("getBanners").then(() => {
    //   if (this.bannersImages.length) {
    //     this.changeWrapperBgImage(0, this.bannersImages, true, true);
    //   }
    // });
    this.$store.dispatch("fetchBlogPostsHomePage");
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

.heading {
  color: #ffffff;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 90px;
  letter-spacing: -0.005em;

  @include respond-to(xs) {
    font-size: 44px;
    line-height: 44px;
    /*text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);*/
  }

  @include respond-to(sm) {
    font-size: 50px;
    line-height: 50px;
    /*text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);*/
  }
}

.no-projects {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  margin-top: 100px;

  @include respond-below(lg) {
    font-size: 20px;
    margin-top: 70px;
  }

  @include respond-below(sm) {
    font-size: 15px;
    margin-top: 55px;
  }
}

.and-much-more-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 115px;
  position: relative;
  height: 56px;
  margin-bottom: -100px;

  @include respond-to(xs) {
    margin-top: 55px;
  }

  @include respond-to(sm) {
    margin-top: 55px;
  }
  &-text {
    position: relative;
    z-index: 1;
    &-shadow {
      position: absolute;
      top: 4px;
      left: 1.5px;
      z-index: -1;
      width: 100%;
    }
  }
  a {
    position: relative;
    text-decoration: none;
    background: linear-gradient(72.12deg, #4d4d4d 2.57%, #121212 126.49%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-height: none;
    padding: 15px 50px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    max-width: 260px;
    /* identical to box height, or 29px */

    letter-spacing: 0.105em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;

    @include respond-below(md) {
      border: 1px solid #000000;
      font-size: 14px;
      line-height: 130%;
      padding: 17px 28px;
    }

  }
}

.active-projects-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-below(xs-plus) {
    flex-direction: column;
  }

  @include respond-above(sm) {
    flex-wrap: wrap;
  }

  @include respond-between(xs, sm) {
    flex-wrap: wrap;
  }
}

.completed-projects,
.about-us-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* flex-wrap: wrap; */
  margin-top: 40px;
  min-width: 100%;
  width: 100%;
  gap: 16px;

  @include respond-below(xs-plus) {
    flex-direction: column;
  }

  @include respond-between(xs, sm) {
    flex-wrap: wrap;
  }

  @include respond-to(xs) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 660px) {
    grid-template-columns: 1fr!important;
  }

  @include respond-between(xs, lg) {
    grid-template-columns: 1fr 1fr;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 1280px;
    position: relative;
    margin-top: 100px;

    @include respond-to(xs) {
      margin-top: 40px;
      align-items: flex-start;
    }
  }

  &-upper-image {
    background: url("../assets/completed-projects-upper-image.svg") no-repeat
      center;
    position: absolute;
    top: -33px;
    left: 65px;
    width: 253px;
    height: 233px;

    @include respond-to(xs) {
      top: 44px;
      right: -31px;
      left: unset;
    }
  }

  &-bottom-image {
    background: url("../assets/completed-projects-bottom-image.svg") no-repeat
      center;
    position: absolute;
    width: 253px;
    height: 233px;
    bottom: -25px;
    right: -31px;
  }
}

.about-us-block {
  max-width: 1000px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.05em;
  justify-content: flex-start;

  p {
    margin: 0 0 20px;
  }
}

.sub-title {
  color: #fff;
  font-family: "Space Mono", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  margin-top: 15px;
  max-width: 940px;
  text-align: center;

  @include respond-to(xs) {
    font-size: 18px;
  }

  @include respond-to(sm) {
    font-size: 20px;
    margin-top: 10px;
  }
}

.title-wrapper {
  align-self: start;

  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 130%;
  /* or 62px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.075em;

  /* Linear */

  background: linear-gradient(142.91deg, #080607 -32.36%, #374544 68.18%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @include respond-to(xs) {
    /*max-width: 200px;*/
  }

  @include respond-to(sm) {
  }

  h1 {
    @include respond-to(sm) {
      font-size: 36px;
    }
  }

  &.centered {
    align-self: center;
  }
}

.details-button button {
  position: relative;
  margin-top: 41px;
  background: linear-gradient(180deg, #6AFFCE 0%, #79F6B6 100%);
  border: 0.7px solid #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  max-height: none;
  padding: 15px 50px;

  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 130%;
  /* identical to box height, or 29px */

  letter-spacing: 0.105em;
  text-transform: uppercase;

  color: #ffffff;

  @include respond-to(xs) {
    font-size: 16px;
    padding: 13px 30px;
  }

  @include respond-to(sm) {
    font-size: 15px;
    padding: 11px 25px;
    margin-top: 27px;
  }
}

$animation-speed: 1s;

.brawl {
  letter-spacing: -4px;
}

.banners {
  margin-top: 120px;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
  background: radial-gradient(
      55.22% 90.9% at 50.79% 44.82%,
      #454852 0%,
      #1d2026 100%
    )
    no-repeat;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 45px;
  padding-bottom: 210px;

  transition: 0.5s ease all;

  @include respond-to(xs) {
    padding-top: 25px;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;
    margin-top: 40px;
  }

  @include respond-to(sm) {
    max-height: 305px;
  }

  @include respond-between(sm, xl) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include respond-above(lg) {
    min-height: 325px;
  }

  .carousel-wrapper {
    position: relative;
    max-width: 1200px;
    width: 100%;


  }
}
.button-shadow {
  position: absolute;
  max-width: 320px;
  width: 100%;
  height: 58px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  @include respond-to(xs) {
    height: 48px;
  }

  @include respond-to(sm) {
    height: 44px;
  }

}
.button-shadow-completed {
  position: absolute;
  max-width: 320px;
  width: 100%;
  height: 58px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  @include respond-to(xs) {
    height: 56px;
  }

  @include respond-to(sm) {
    height: 56px;
  }

}

  .secondary{
    position: relative;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    max-width: 260px;
    /* identical to box height, or 29px */

    letter-spacing: 0.105em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;
  }
  .back{
    background: linear-gradient(180deg, #6AFFCE 0%, #79F6B6 100%);
  }
</style>
