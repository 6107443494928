<template>
  <main-layout about-us>
    <template v-slot:top-section>
      <top-section-wrapper about-us>
        <top-section-content show-right-side>
          <template v-slot:top-section-sub-heading>
            <span class="about-us-subheading">
              Launchpad & Community Hub. Powered by zkSync.
            </span>
          </template>
          <template v-slot:top-section-footer>
            <button-component
              @click="openWalletModal"

              class="secondary connectBtn"
              :disabled="mintLoading"
            >
              {{ !mintLoading ? "Mint Genesis NFT" : "Minting..." }}
            </button-component>
          </template>
          <template v-slot:top-section-right-side>
            <img class="zkNft" src="../assets/zkNft.png" alt="zkRaise" />
          </template>
        </top-section-content>
      </top-section-wrapper>
    </template>
  </main-layout>
  <WalletModal v-if="isShow" @closeModal="closeModal" @handleConnect="handleConnect" @connectWalletWalletConnect="connectWalletWalletConnect"></WalletModal>

</template>

<script>
import TopSectionWrapper from "@/components/TopSectionWrapper";
import MainLayout from "@/layouts/main";
import TopSectionContent from "@/components/TopSectionContent";
import ButtonComponent from "@/components/Button";
import WalletModal from "@/components/WalletModal";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "AboutUs",
  components: {
    ButtonComponent,
    TopSectionContent,
    TopSectionWrapper,
    MainLayout,
    WalletModal,
  },
  head: {
    meta: [
      {
        name: "description",
        content:
          "Launchpad and ambassador platform for crypto projects. Invest in tokens and NFT.",
      },
    ],
  },
  created() {
    window.addEventListener("scroll", this.scrollFunc);
  },
  unmounted() {
    window.removeEventListener("scroll", this.scrollFunc);
  },
  async mounted() {
    await this.monitorTheNetwork();
  },

  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapState(["userProfile", "mintLoading"]),
    ...mapGetters(["web3"]),
  },
  data() {
    return {
      aboutUsBanner: "",
      show: false,
      animation: false,
      x: 0,
      y: 0,
      count: 0,
      isShow: false,
    };
  },
  methods: {
    ...mapActions(["connectWallet", "mintNFT", "monitorTheNetwork", "connectWalletConnect"]),
    scrollFunc() {
      if (window.pageYOffset > 1300 && window.pageYOffset < 2500) {
        if (!this.count) {
          this.show = true;
          this.count++;
        }
        this.x = window.pageYOffset - 1800;
      } else if (window.pageYOffset < 500 || window.pageYOffset > 2500) {
        this.show = false;
      }
    },
    async openWalletModal() {
      if (!this.userProfile.userERCAddress){
        this.isShow = !this.isShow;
      } else {
        await this.$store.dispatch(
                "changeChainToZkSync",
                await this.web3.eth.getChainId()
        );

        this.$store.commit("MINT_LOADING", true);
        await this.mintNFT();
      }
    },
    closeModal() {
      this.isShow = !this.isShow;
    },
    async connectWalletWalletConnect() {
      await this.connectWalletConnect()
      await this.$store.dispatch(
              "changeChainToZkSync",
              await this.web3.eth.getChainId()
      );

      this.$store.commit("MINT_LOADING", true);
      await this.mintNFT();
    },
    async handleConnect() {
      await this.connectWallet();
      await this.$store.dispatch(
              "changeChainToZkSync",
              await this.web3.eth.getChainId()
      );

      this.$store.commit("MINT_LOADING", true);
      await this.mintNFT();

    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.zkNft {
  position: absolute;
  width: 442px;
  height: 476px;
  top: -100px;
  right: -200px;
  @include respond-below(lg) {
    top: -100px;
    right: -60px;
  }
  @include respond-below(md) {
    width: 250px;
    height: 268px;
    top: 0;
    right: 0;
  }
  @include respond-below(sm) {
    width: 250px;
    height: 268px;
  }
  @include respond-below(md) {
    width: 250px;
    height: 268px;
  }
  @media (max-width: 380px) {
    width: 220px;
    height: 240px;
  }
}
img {
  width: 100%;
}

.connectBtn {
  max-height: none;
  width: 212px;
  height: 56px;
  background: #ffffff;
  border-radius: 8px;
  font-family: "Mona Sans";
  font-style: normal;
  font-weight: 600;
  color: #000000;
  @include respond-below(md) {
    width: 301px;
  }
  @media (max-width: 380px) {
    width: 220px;
  }
}

.about-us {
  &-heading {
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 700;
    font-size: 140px;
    line-height: 207px;
    letter-spacing: -0.005em;

    color: #ffffff;
    text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000;

    &-text {
      position: relative;

      &-shadow {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: -1;

        @include respond-below(lg) {
          top: 14px;
          left: 3px;
          z-index: -1;
        }
      }
    }

    /*border: 1px solid #000000;*/
    /*<!--text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,-->*/
    /*<!--  1px 1px 0 #000, 1px 0px 0px #fff, 0px 1px 0px #fff, 2px 1px 0px #fff,-->*/
    /*<!--  1px 2px 0px #fff, 3px 2px 0px #fff, 2px 3px 0px #fff, 4px 3px 0px #fff,-->*/
    /*<!--  3px 4px 0px #fff, 5px 4px 0px #fff, 3px 5px 0px #000, 6px 5px 0px #000,-->*/
    /*<!--  -1px 2px 0 black, 0 3px 0 #000, 1px 4px 0 #000, 2px 5px 0px #000,-->*/
    /*<!--  2px -1px 0 #000, 3px 0 0 #000, 4px 1px 0 #000, 5px 2px 0px #000,-->*/
    /*<!--  6px 3px 0 #000, 7px 4px 0 #000;-->*/

    @include respond-to(xs) {
      font-size: 44px;
      line-height: 44px;
    }

    @include respond-to(sm) {
      font-size: 50px;
      line-height: 50px;
    }
    @include respond-to(md) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  &-subheading {
    font-family: "Mona Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 110%;
    /* or 62px */
    letter-spacing: -0.02em;
    color: #ffffff;

    @include respond-to(xs) {
      font-size: 32px;
    }

    @include respond-to(sm) {
      font-size: 32px;
    }
    @include respond-to(md) {
      /*font-size: 32px;*/
    }
    @media (max-width: 380px) {
      font-size: 20px;
    }
  }
}

.button-shadow {
  position: absolute;
  max-width: 210px;
  width: 100%;
  height: 58px;
  border: 1px solid #000;
  left: 6px;
  top: 6px;
  z-index: -1;
  /*<!--@include respond-to(xs) {-->*/
  /*<!--  height: 45px;-->*/
  /*<!--}-->*/

  /*<!--@include respond-to(sm) {-->*/
  /*<!--  max-width: 138px;-->*/
  /*<!--  height: 42px;-->*/
  /*<!--}-->*/
}
button {
  &:disabled {
    background: #ffffff !important;
    color: #000000;
    cursor: help;
  }
}
</style>
