<template>
  <div class="block">
    <div class="block-soft">
      <div class="block-soft-left">
        <div class="block-soft-left-top">
          {{ timerHead }}
        </div>
        <div class="block-soft-left-bottom">
          {{ formattedTime }}
        </div>
      </div>
      <div class="block-type">{{ typeName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallTimer",
  data() {
    return {
      ended: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      currentTime: this.activeDaysLeftParsed,
    };
  },
  props: {
    timerHead: String,
    activeDaysLeftParsed: {
      type: Object,
      required: true,
      validator: (value) => {
        return ["days", "hours", "minutes", "seconds"].every(
          (key) => key in value
        );
      },
    },
    typeName: String,
  },
  computed: {
    isCountdown() {
      return this.timerHead.includes("ends");
    },
    trimmedName() {
      const nameLength = 15;
      return this.name.length > nameLength
        ? `${this.name.substring(0, nameLength)}...`
        : this.name;
    },
    formattedTime() {
      return `${this.currentTime.days}d : ${this.currentTime.hours}h : ${this.currentTime.minutes}m : ${this.currentTime.seconds}s`;
    },
  },
  methods: {
    startTimer() {
      let totalSeconds = this.convertToSeconds(this.currentTime);

      this.intervalId = setInterval(() => {
        if (this.isCountdown) {
          totalSeconds--;
          if (totalSeconds < 0) {
            clearInterval(this.intervalId);
            this.currentTime = this.convertToTimeObj(0);
            return;
          }
        } else {
          totalSeconds++;
        }
        this.currentTime = this.convertToTimeObj(totalSeconds);
      }, 1000);
    },
    convertToSeconds(timeObj) {
      return (
        parseInt(timeObj.days) * 86400 +
        parseInt(timeObj.hours) * 3600 +
        parseInt(timeObj.minutes) * 60 +
        parseInt(timeObj.seconds)
      );
    },
    convertToTimeObj(totalSeconds) {
      const days = Math.floor(totalSeconds / 86400);
      totalSeconds %= 86400;
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;

      return {
        days: days.toString().padStart(2, "0"),
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      };
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped lang="scss">
.block {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  &-name {
    font-family: "Mona Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #3d3d45;
  }
  &-type {
    font-family: "Mona Sans";
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;
    gap: 8px;
    background: #ddfadc;
    border-radius: 6px;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    letter-spacing: 0.014em;
    color: #3ea33b;
  }
  &-soft {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 8px;
    width: 100%;
    height: 72px;
    border: 1px solid #d6d6e4;
    border-radius: 8px;

    &-left {
      display: flex;
      flex-direction: column;
      align-items: start;
        gap: 4px;
      &-top {
        font-family: "Mona Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.014em;
        color: #3d3d45;
      }

      &-bottom {
        font-family: "Mona Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        hanging-punctuation: first last;
        color: #3d3d45;
      }
    }
  }
}
</style>
