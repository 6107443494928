<template>
  <div class="pagination-wrapper">
    <ul class="pagination-list" v-if="totalPages && totalPages > 1">
      <li
        class="pagination-link arrow"
        :class="{disabled: currentPage === 1}"
        @click="currentPage !== 1 ? navigate(currentPage - 1) : null">
        <img src="../assets/zkRaise_icons/pagination-arrow-left.svg" alt="account" class="next-icon">
      </li>
      <li v-for="index in pages"
        :key="`pagination-link-${index}`"
        class="pagination-link"
        :class="{active: index === currentPage}"
        @click="navigate(index)"
        >
        {{ index }}
      </li>
      <li
        class="pagination-link arrow"
        :class="{disabled: currentPage === totalPages}"
        @click="currentPage !== totalPages ? navigate(currentPage + 1) : null">
        <img src="../assets/zkRaise_icons/pagination-arrow-left.svg" style="transform: rotate(180deg)" alt="account" class="next-icon">
      </li>
    </ul>
  </div>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { useRouter } from 'vue-router'

const router = useRouter();

//eslint-disable-next-line no-unused-vars
const props = defineProps({
  currentPage: Number,
  totalPages: Number,
  url: Object
});
const pages = computed(() => {
  const totalPages = props.totalPages;
  const currentPage = props.currentPage;
  if (totalPages) {
    if (totalPages > 2 && totalPages - currentPage < 3) {
      return [...Array(totalPages+1).keys()].slice(totalPages - 2);
    }

    if (totalPages < 3) {
      return [...Array(totalPages+1).keys()].slice(1);
    }

    return [...Array(3 + currentPage).keys()].slice(currentPage);
  }

  return [];
});
const navigate = (index) => {
  let params = {};

  if (props.url.params) {
    for (let i=0; i<props.url.params.length; i++) {
      params[props.url.params[i]] = index
    }
  }

  router.push({
    name: props.url.name,
    query: params
  })
}
</script>
<script>
export default {
  name: "GenericPagination",
}
</script>
<style lang="scss">
@import "@/assets/styles/mixins";
  .pagination {
    &-list {
      margin-top: 25px;
      padding-left: 0;
    }

    &-link {
      display: inline;
      cursor: pointer;
      padding: 12px 16px;
      background: #FFFFFF;
      border-radius: 24px;
      font-family: 'Mona Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #3D3D45;
      border: 1px solid #d6d6e4;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &.active,
      &:hover {
        padding: 12px 16px;
        background: #3D3D45;
        border-radius: 24px;
        font-family: 'Mona Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        border: 1px solid #3D3D45;


      }
      .prev-icon,
      .next-icon path {
        fill: #FFFFFF;

      }

    }
  }
.arrow:hover{
  background: #ffffff!important;
}
</style>