const state = {
  web3: null,
  web3ProviderName: 'metamask',
  provider: null,
  banners: [],
  blockchainInfoWeb3: null,
  blogPosts: [],
  blogPostsHomePage: [],
  blogCategories: [],
  industriesInnovationPage: [],
  projectsInnovationPage: [],
  projectsOTCPage: [],
  userProfile: {
    email: '',
    userERCAddress: '',
    wallets: null,
    social: null,
    kyc: null,
    blogPostsLikes: [],
    interests: {}
  },
  tokenContract: null,
  tokenContractInfo: null,
  oldTokenContractInfo: null,
  investments: [],
  oldInvestments: [],
  pagination: {},
  projects: [],
  i18n: {},
  socialNetworks: {},
  requestStatus: 'idle',
  modalData: null,
  swapAmount: 0,
  CPLTSwapContractAbi: JSON.parse(process.env.VUE_APP_SWAP_CONTRACT_ABI),
  CPLTSwapContractAddress: process.env.VUE_APP_SWAP_CONTRACT_ADDRESS,
  CPLTContractAbi: JSON.parse(process.env.VUE_APP_MAIN_TOKEN_CONTRACT_ABI),
  CPLTContractAddress: process.env.VUE_APP_MAIN_TOKEN_CONTRACT_ADDRESS,
  ATDContractAbi: JSON.parse(process.env.VUE_APP_OLD_TOKEN_CONTRACT_ABI),
  ATDContractAddress: process.env.VUE_APP_OLD_TOKEN_CONTRACT_ADDRESS,
  allowanceIncreaseStatus: '',
  newTokenName: 'USDT',
  kycStatus: 'verify',
  verifyKycURL: `${process.env.VUE_APP_FRACTAL_URL}/authorize?client_id=${process.env.VUE_APP_FRACTAL_CLIENT_ID}&redirect_uri=https://${window.location.host}/kyc-finished&response_type=code&scope=contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread`,
  enableWC2022Features: false,
  enableXmaxEvent: false,
  partnerTypes: [],
  partners: [],
  leaderboard: [],
  currentUserLeaderboard: {
    numberOfProjects: 0,
    totalInvested: 0,
    daoValue: 0,
    totalScore: 0,
    atdBoost: 0,
    erc20Address: '',
  },
  userLeaderboardStakes: [],
  atdContractData: {
    balance: '',
    symbol: '',
    decimals: '',
  },
  burnLoading: false,
  stakeLoading: false,
  isAgree: false,
  currentNetworkDropdown: null,
  walletConnectProvider: null,
  accessToken: localStorage.getItem('access_token'),
  tokenExpiration: localStorage.getItem('token_expiration'),
  userActivity: [],
  userStakesAndPenalties: {},
  unstakeLoading: false,
  mintLoading: false,
};

export default state;
