<template>
  <div class="btn-wrapper">
    <div v-if="userProfile.userERCAddress" class="balance">
      <img src="../../assets/zkRaise_icons/tron.svg" alt="" />
      <div>4413.43 USDT</div>
    </div>
    <button-component
      v-if="!userProfile.userERCAddress"
      :onClick="openWalletModal"
      class="cta"
    >
      Sign Up
    </button-component>
    <button-component
      v-else
      class="cta user-erc20-address"
    >
      {{ userERCTrimmed }}
    </button-component>
  </div>
  <WalletModal
    v-if="isShow"
    @closeModal="closeModal"
    @handleConnect="handleConnect"
    @connectWalletWalletConnect="connectWalletWalletConnect"
  ></WalletModal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ButtonComponent from "@/components/Button";
import WalletModal from "@/components/WalletModal";

export default {
  name: "ConnectWalletButton",
  components: {
    ButtonComponent,
    WalletModal,
  },
  data() {
    return {
      hoverButtonLabel: null,
      isShow: false,
    };
  },
  props: {
    windowWidth: Number,
  },
  computed: {
    ...mapGetters(["userERCTrimmed"]),
    ...mapState(["userProfile"]),
  },
  methods: {
    ...mapActions(["connectWallet", "logOut", "connectWalletConnect"]),
    async handleConnect() {
      await this.connectWallet();
    },
    async connectWalletWalletConnect() {
      await this.connectWalletConnect();
    },
    proceedLogout() {
      this.$gtm.trackEvent({
        event: "ConnectWalletButton_Logout", // Event type [default = 'interaction'] (Optional)
        category: "ConnectWalletButton",
        action: "Logout",
        label: this.userProfile.userERCAddress,
        value: 1,
      });

      if (!this.$isMobile()) {
        this.logOut();
      }
      // this.$router.push("/");
      this.$store.commit("CLEAR_CURRENT_USER_LEADERBOARD", {
        numberOfProjects: 0,
        totalInvested: 0,
        daoValue: 0,
        totalScore: 0,
        atdBoost: 0,
        erc20Address: "",
      });
    },
    onMouseOver() {
      if (!this.$isMobile()) {
        this.hoverButtonLabel = "Disconnect";
      }
    },
    onMouseOut() {
      this.hoverButtonLabel = this.userERCTrimmed;
    },
    closeModal() {
      this.isShow = !this.isShow;
    },
    openWalletModal() {
      this.isShow = !this.isShow;
      // this.connectWallet()
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";

button {
  @include respond-to(sm) {
    font-size: 14px;
    margin-top: 5px;
  }

  @include respond-above(md) {
    width: 197px;
  }
}
.cta {
  font-family: "Mona Sans";
  padding: 4px 8px;
  gap: 8px;
  width: 152px;
  height: 32px;
  text-align: center;
  background: #374bff;
  border-radius: 6px;
  box-shadow: none;
  font-family: 'Mona Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
.btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px;
  gap: 4px;
  width: fit-content;
  height: 40px;
  background: #e6e6f5;
  border-radius: 8px;
}
.balance {
  display: flex;
  padding-left: 12px;
  gap: 8px;
  padding-right: 12px;
  align-items: center;
  img {
    margin-top: -2px;
  }
}
</style>
