<template>
  <footer>
    <div class="zkRaise">zkRaise &copy; 2024</div>
    <div class="networks">
      <a href="https://formyfi.io/quest/9438a8b6-9879-4e98-8521-f222bc3630c9" target="_blank">Talk to us</a>
<!--        <div style="margin-top: 3px">Follow us</div>-->
<!--        <a href="/" target="_blank">-->
<!--        <img src="../assets/zkRaise_icons/telegram.svg" alt="" />-->
<!--      </a>-->
      <a href="mailto:hi@zkraise.com" target="_blank">
        <img src="../assets/zkRaise_icons/email.svg" alt=""   style="  width: 30px; height: 30px;"/>
      </a>
      <a href="https://x.com/raise_on_zk" target="_blank">
        <img src="../assets/zkRaise_icons/twitter.svg" alt="" />
      </a>
<!--      <a href="/" target="_blank">-->
<!--        <img src="../assets/zkRaise_icons/discord.svg" alt="" />-->
<!--      </a>-->
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { scrollToSmoothly } from "@/helpers";
import { useToast } from "vue-toastification";

export default {
  name: "FooterComponent",
  components: {},
  setup() {
    const toast = useToast();

    return { toast };
  },
  computed: {
    staticEndpoint() {
      return process.env.VUE_APP_STATIC_ENDPOINT;
    },
    ...mapGetters(["i18n", "socialNetworks"]),
    ...mapState(["userProfile", "kycStatus", "verifyKycURL"]),
  },
  methods: {
    scrollToSmoothly,
    ...mapActions(["connectWallet"]),
    handleParticipate() {
      if (this.kycStatus === "approved") {
        this.toast.info("Your KYC has already been approved.");
      } else {
        window.open(this.verifyKycURL);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: calc(100% - 40px);
  height: 72px;
  border-top: 1px solid #D6D6E4;
  background: #f5f5ff;

  @include respond-below(lg) {
    /*margin-top: 24px;*/
  }
  @media (max-width: 320px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-around;
  }
}
.zkRaise {
  height: 24px;
  font-family: "Mona Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  color: #1a1a23;
}
.networks {
  display: flex;
  gap: 24px;
  font-family: "Mona Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0e0e16;
  @media (max-width: 350px) {
    gap: 10px;
  }
}
  a{
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
  }
</style>
