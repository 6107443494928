<template>
  <header>
    <div class="content">
      <router-link to="/deals">
        <img class="logo" src="../assets/zkRaiseLogo.svg" alt="zkRaise" />
      </router-link>
      <!--        <mq-responsive target="xs">-->
<!--      <button-->
<!--        type="button"-->
<!--        class="show-menu"-->
<!--        :class="isMobileMenuOpened ? 'active' : ''"-->
<!--        @click="toggleMobileMenu"-->
<!--        v-if="windowWidth && windowWidth < 450"-->
<!--      >-->
<!--        Open menu-->
<!--        <span></span>-->
<!--      </button>-->
      <div class="balance-block">
        <img class="balance-block-notification" src="../assets/zkRaise_icons/notification.svg" alt="">
        <ConnectWalletButton/>
        <img class="balance-block-user-logo" src="../assets/zkRaise_icons/user-logo.svg" alt="">
      </div>


      <!--        </mq-responsive>-->
<!--      <div-->
<!--        class="overlay"-->
<!--        v-if="isMobileMenuOpened"-->
<!--        @click="toggleMobileMenu"-->
<!--      ></div>-->
<!--      <div v-if="isMobileMenuOpened" class="mobile-menu">-->
<!--        <ul>-->
<!--          <li>-->
<!--            <ConnectWalletButton v-if="!userProfile.userERCAddress" />-->
<!--            <div v-else @click="logOut">Logout</div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </header>
</template>
<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
// import ButtonComponent from "@/components/Button";

// import { MqResponsive } from "vue3-mq";
import ConnectWalletButton from "@/components/buttons/TemporaryConnectWalletButton";

export default {
  name: "HeaderComponent",
  components: {
    // ButtonComponent,
    ConnectWalletButton,
    // MqResponsive,
  },
  data() {
    return {
      isMobileMenuOpened: false,
      isOpenDeals: false,
      isOpenSettings: false,
      click: false,
      windowWidth: null,
    };
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    userProfile(newVal, oldVal) {
      if (newVal?.userERCAddress) {
        this.$store.dispatch("fetchLeaderboard");
      }
    },
  },
  computed: {
    mainMenu() {
      return this.$router.options.routes.filter(
        (menuItem) => menuItem.meta?.inMenu
      );
    },
    ...mapGetters(["accessToken", "tokenExpiration", "userERCTrimmed"]),
    ...mapState(["userProfile", "provider", "currentUserLeaderboard"]),
  },
  async created() {
    document.addEventListener("click", this.closeDropdown);
  },
  async mounted() {
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);

    const currentTime = Date.now();
    const FIVE_MINUTES = 5 * 60 * 1000;

    if (this.accessToken) {
      if (currentTime < this.tokenExpiration) {
        if (this.tokenExpiration - currentTime < FIVE_MINUTES) {
          this.refreshToken();
        } else {
          if (this.provider === null) {
            await this.initWeb3();
          }
          if (!this.userProfile.userERCAddress) {
            this.fetchUserProfile();
            this.setTokenContract();
          }
        }
      } else {
        this.logOut();
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeDropdown);
    window.removeEventListener("resize", this.updateWindowWidth);
  },

  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    closeDropdown() {
      this.isOpenDeals = false;
      this.isOpenSettings = false;
    },
    proceedLogout() {
      this.$gtm.trackEvent({
        event: "ConnectWalletButton_Logout", // Event type [default = 'interaction'] (Optional)
        category: "ConnectWalletButton",
        action: "Logout",
        label: this.userProfile.userERCAddress,
        value: 1,
      });
      this.logOut();
      this.isOpenDeals = false;
      this.isOpenSettings = false;
    },
    openDeals() {
      if (this.isOpenDeals) {
        this.isOpenDeals = !this.isOpenDeals;
      } else {
        setTimeout(() => {
          this.isOpenDeals = !this.isOpenDeals;
        }, 10);
      }
    },
    openSettings() {
      if (this.isOpenSettings) {
        this.isOpenSettings = !this.isOpenSettings;
      } else {
        setTimeout(() => {
          this.isOpenSettings = !this.isOpenSettings;
        }, 10);
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpened = !this.isMobileMenuOpened;
    },
    toggleProfileModal() {
      this.toggleModal({
        modalID: "modal-profile",
      });
    },
    ...mapActions([
      "refreshToken",
      "logOut",
      "fetchUserProfile",
      "setTokenContract",
      "initWeb3",
    ]),
    ...mapMutations({
      toggleModal: "TOGGLE_MODAL",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";

.link {
  cursor: pointer;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 60px);
  height: 72px;
  background: #f5f5ff;

  padding: 0 30px;
  /* Secondary/70 */
  border-bottom: 1px solid #D6D6E4;

  @include respond-to(xs) {
    z-index: 20;
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  .logo{
    width: 128px;
    height: 21px;
  }
}

.main-menu {
  list-style-type: none;
  display: flex;
  transition: all 0.2s ease-in-out;
  align-items: center;
  margin: 0;
  /*height: 40px;*/

  &-dropdown {
    border-radius: 9px;
    font-family: "Space Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    /* or 23px */

    display: flex;
    align-items: center;
    letter-spacing: -0.03em;

    /* #141414 */

    color: #141414;
    /*padding: 4px 13px;*/
    cursor: pointer;
    .wallet {
      margin-right: 10px;
    }
    .arrow {
      width: 12px;
      height: 6px;
      margin-left: 8px;
    }
    @include respond-to(md) {
      font-size: 16px;
    }

    @include respond-to(sm) {
      font-size: 14px;
      height: auto;
      padding-top: 1px;
      padding-bottom: 0;
    }
  }
  @include respond-above(xs) {
    height: 40px;
  }
  &-content {
    position: absolute;
    padding: 20px 22px 20px 7px;
    background: #f3f3f3;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.07);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 9px;
    width: fit-content;
    border: 0.2px solid rgba(143, 219, 252, 0.1);
    margin-top: 5px;
    ul {
      list-style: none;
      padding: 0;
      li {
        font-family: "Space Mono";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */

        display: flex;
        align-items: center;
        letter-spacing: -0.03em;

        /* #141414 */

        color: #141414;
        width: 100%;
        max-width: 250px;
        height: 45px;
        padding-left: 15px;
        margin: 0;
        @include respond-to(sm) {
          font-size: 14px;
        }
        a {
          display: inline-flex;
          align-items: center;
          width: 100%;
          height: 45px;
        }
        &:hover {
          cursor: pointer;
          background: #eae7e7;
        }
        img {
          margin-right: 10px;
          width: 16px;
          height: 17px;
        }
      }
    }
  }

  @include respond-to(xs) {
    background: #f3f3f3;
    flex-direction: column;
    left: -100%;
    padding: 40px 0 30px 69px;
    position: fixed;
    top: -16px;
    width: calc(100% - 115px);
    z-index: 503;
  }

  li {
    margin-right: 40px;
    ul {
      list-style: none;
      @include respond-to(xs) {
        padding-top: 20px;
      }
    }

    @include respond-to(xs) {
      align-items: start;
      margin-bottom: 25px;
      font-size: 18px;
    }

    @include respond-to(sm) {
      align-items: start;
      margin-right: 30px;
    }
    a {
      font-family: "Space Mono";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */

      display: flex;
      align-items: center;
      letter-spacing: -0.03em;
      /* #141414 */

      color: #141414;
      gap: 10px;

      text-decoration: none;
      @include respond-to(md) {
        font-size: 16px;
      }

      @include respond-to(sm) {
        font-size: 13px;
      }
    }
  }

  @include respond-to(xs) {
    height: auto;
    align-items: start;
  }

  @include respond-to(sm) {
    /*align-items: start;*/
  }
}

.user-icon {
  width: 16px;
  height: 17px;

  .profile {
    height: 19px;
    width: 25px;
  }

  .investments {
    height: 22px;
    width: 25px;
  }
}

.right-side-wrapper {
  display: flex;

  &.logged-in {
    .main-menu {
      div {
        @include respond-above(sm) {
          display: flex;
        }
      }

      li {
        @include respond-to(sm) {
          margin-right: 17px;
        }
      }
    }
  }
}

.mobile-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f3f3f3;
  box-shadow: 0 0 65px rgba(0, 0, 0, 0.3);
  padding: 23px 32px 18px 23px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .logged-actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.show-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 0;
  z-index: 501;
  border: none;
  right: 20px;
  top: 20px;
  height: 20px;
  width: 18px;
  background: url(https://a2dao.com/img/menu.b471b170.svg) no-repeat center;
  position: absolute;

  &:focus,
  &:active {
    outline: none;
  }

  &.active {
    span {
      width: 0;
    }

    &:before,
    &:after {
      width: 31px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
.main-menu-deals {
  @include respond-to(sm) {
    margin-right: 0;
  }
}
.connectBtn {
  font-family: "Mona Sans";
  height: 48px;
  background: #1a1a23;
  border-radius: 8px;
  color: #ffffff;
  max-height: none;
  font-style: normal;
  font-weight: 600;
  width: 198px;
}

.mobile-menu {
  font-family: "Mona Sans";
  position: absolute;
  z-index: 100000;
  background: white;
  width: 300px;
  left: 0;
  top: 0;
  font-size: 18px;
  ul {
    list-style: none;
    li {
      min-height: 40px;
      font-size: 23px;
      display: flex;
      align-items: center;
      div {
        width: 100%;
      }

      &:hover {
        background: #eae7e7;
        cursor: pointer;
      }
    }
  }
}
  .balance-block{
    display: flex;
    gap: 35px;
    align-items: center;
    &-notification{
      width: 20px;
    }
    &-user-icon{
    width: 48px;
    }
  }
</style>
